import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
*/
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */
function withImageData(WrappedComponent) {
  return props => (
    <StaticQuery
      query={graphql`
        query {
          settingsImage: file(relativePath: { eq: "settingsIcon.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          closeImage: file(relativePath: { eq: "closeIcon.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          soundOnImage: file(relativePath: { eq: "soundOnIcon.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          soundOffImage: file(relativePath: { eq: "soundOffIcon.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          fullscreenOnImage: file(relativePath: { eq: "fullscreenOnIcon.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          fullscreenOffImage: file(relativePath: { eq: "fullscreenOffIcon.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          pinImage: file(relativePath: { eq: "pinIcon.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          unpinImage: file(relativePath: { eq: "unpinIcon.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          captionsImage: file(relativePath: { eq: "captionsIcon.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          chatImage: file(relativePath: { eq: "chatIcon.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          adImage: file(relativePath: { eq: "adIcon.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bslImage: file(relativePath: { eq: "bslIcon.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          fsImage: file(relativePath: { eq: "fsIcon.png" }) {
            childImageSharp {
              fluid(maxWidth: 40, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => <WrappedComponent {...props} imageData={data} />}
    />
  );
}

const SettingsIcon = withImageData(props => (
  <Img fluid={props.imageData.settingsImage.childImageSharp.fluid} className="settings-icon" alt="Settings" />
));

const CloseIcon = withImageData(props => (
  <Img fluid={props.imageData.closeImage.childImageSharp.fluid} className="close-icon" alt="Close menu" />
));

const SoundOnIcon = withImageData(props => (
  <Img fluid={props.imageData.soundOnImage.childImageSharp.fluid} className="soundon-icon" alt="Sound on" />
));

const SoundOffIcon = withImageData(props => (
  <Img fluid={props.imageData.soundOffImage.childImageSharp.fluid} className="soundoff-icon" alt="Sound off" />
));

const PinIcon = withImageData(props => (
  <Img fluid={props.imageData.pinImage.childImageSharp.fluid} className="pin-icon" alt="Sound on" />
));

const UnpinIcon = withImageData(props => (
  <Img fluid={props.imageData.unpinImage.childImageSharp.fluid} className="unpin-icon" alt="Sound off" />
));

const ChatIcon = withImageData(props => (
  <Img fluid={props.imageData.chatImage.childImageSharp.fluid} className="chat-icon" alt="Live support" />
));

const CaptionsIcon = withImageData(props => (
  <Img fluid={props.imageData.captionsImage.childImageSharp.fluid} className="captions-icon" alt="Captions" />
));

const ADIcon = withImageData(props => (
  <Img fluid={props.imageData.adImage.childImageSharp.fluid} className="audio-description-icon" alt="Audio Description" />
));

const BSLIcon = withImageData(props => (
  <Img fluid={props.imageData.bslImage.childImageSharp.fluid} className="bsl-icon" alt="BSL" />
));

const FSIcon = withImageData(props => (
  <Img fluid={props.imageData.fsImage.childImageSharp.fluid} className="fullscreen-icon" alt="Fullscreen" />
));

const FullscreenOnIcon = withImageData(props => (
  <Img fluid={props.imageData.fullscreenOnImage.childImageSharp.fluid} className="fullscreenon-icon" alt="Fullscreen on" />
));

const FullscreenOffIcon = withImageData(props => (
  <Img fluid={props.imageData.fullscreenOffImage.childImageSharp.fluid} className="fullscreenoff-icon" alt="Fullscreen off" />
));
export { SettingsIcon, CloseIcon, SoundOnIcon, SoundOffIcon, PinIcon, UnpinIcon, ChatIcon, CaptionsIcon, ADIcon, BSLIcon, FSIcon, FullscreenOnIcon, FullscreenOffIcon };
